import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

export const persons = new FeatureLayer({
  url: "https://opencity.vplanas.lt/map/rest/services/P_VilniausDNR/VilniausDNR/MapServer/5",
  // outFields: ["*"],
  title: "Asmenys",
});

export const biography = new FeatureLayer({
  url: "https://opencity.vplanas.lt/map/rest/services/P_VilniausDNR/VilniausDNR/MapServer/6",
  // outFields: ["*"],
  title: "Biografija",
});

export const related_persons = new FeatureLayer({
  url: "https://opencity.vplanas.lt/map/rest/services/P_VilniausDNR/VilniausDNR/MapServer/10",
  // outFields: ["*"],
  title: "Susije asmenys",
});

export const related_org = new FeatureLayer({
  url: "https://opencity.vplanas.lt/map/rest/services/P_VilniausDNR/VilniausDNR/MapServer/11",
  // outFields: ["*"],
  title: "Susije org",
});

export const related_person_sources = new FeatureLayer({
  url: "https://opencity.vplanas.lt/map/rest/services/P_VilniausDNR/VilniausDNR/MapServer/13",
  // outFields: ["*"],
  title: "Susije saltiniai",
});

export const related_events = new FeatureLayer({
  url: "https://opencity.vplanas.lt/map/rest/services/P_VilniausDNR/VilniausDNR/MapServer/7",
  // outFields: ["*"],
  title: "Susije ivykiai",
});

export const classifications = new FeatureLayer({
  url: "https://opencity.vplanas.lt/map/rest/services/P_VilniausDNR/VilniausDNR/MapServer/8",
  // outFields: ["*"],
  title: "Klasifikatoriai",
});

export const person_buildings = new FeatureLayer({
  url: "https://atviras.vplanas.lt/arcgis/rest/services/PAVELDAS/KPSAE_READ/MapServer/14",
  title: "Asmenų statiniai",
});

export const KP_buildings = new FeatureLayer({
  url: "https://atviras.vplanas.lt/arcgis/rest/services/PAVELDAS/KPSAE_READ/MapServer/0",
  title: "KP statiniai",
});

export const langFields = {
  lt: [
    "Asmenybes_ID",
    "OBJECTID",
    "Kuri_kalba_pagrindine",
    "Nuotrauka",
    "Nuotraukos_aprasymas",
    "Palaidojimas",
    "Vardas_lietuviskai",
    "Vardas_pavarde_EN",
    "Vardas_pavarde_RU",
    "Vardas_pavarde_PL",
    "Vardas_pavarde_KITA",
    "Pseudonimas_ir_slapyvardziai",
    "Veikla_profesija",
    "Veikla_kuryba_trumpai",
    "Asmens_rysys_su_Vilniumi",
    "Apdovanojimai",
    "Palaidojimo_aprasymas",
  ],
  en: [
    "Asmenybes_ID",
    "OBJECTID",
    "Kuri_kalba_pagrindine",
    "Nuotrauka",
    "Nuotraukos_aprasymas",
    "Palaidojimas",
    "Vardas_lietuviskai",
    "Vardas_pavarde_EN",
    "Vardas_pavarde_RU",
    "Vardas_pavarde_PL",
    "Vardas_pavarde_KITA",
    "Pseudonimas_ir_slapyvardziai",
    "Veikla_profesija_ANG",
    "Veikla_kuryba_trumpai_ANG",
    "Asmens_rysys_su_Vilniumi_ANG",
    "Apdovanojimai_ANG",
    "Palaidojimo_aprasymas_ANG",
  ],
  ru: [
    "Asmenybes_ID",
    "OBJECTID",
    "Kuri_kalba_pagrindine",
    "Nuotrauka",
    "Nuotraukos_aprasymas",
    "Palaidojimas",
    "Vardas_lietuviskai",
    "Vardas_pavarde_EN",
    "Vardas_pavarde_RU",
    "Vardas_pavarde_PL",
    "Vardas_pavarde_KITA",
    "Pseudonimas_ir_slapyvardziai",
    "Veikla_profesija_RUS",
    "Veikla_kuryba_trumpai_RUS",
    "Asmens_rysys_su_Vilniumi_RUS",
    "Apdovanojimai_RUS",
    "Palaidojimo_aprasymas_RUS",
  ],
  pl: [
    "Asmenybes_ID",
    "OBJECTID",
    "Kuri_kalba_pagrindine",
    "Nuotrauka",
    "Nuotraukos_aprasymas",
    "Palaidojimas",
    "Vardas_lietuviskai",
    "Vardas_pavarde_EN",
    "Vardas_pavarde_RU",
    "Vardas_pavarde_PL",
    "Vardas_pavarde_KITA",
    "Pseudonimas_ir_slapyvardziai",
    "Veikla_profesija_LEN",
    "Veikla_kuryba_trumpai_LEN",
    "Asmens_rysys_su_Vilniumi_LEN",
    "Apdovanojimai_LEN",
    "Palaidojimo_aprasymas_LEN",
  ],
};

export const langFieldsDict = {
  lt: {
    Vardas_lietuviskai: "Vardas_lietuviskai",
    Veikla_profesija: "Veikla_profesija",
    Veikla_kuryba_trumpai: "Veikla_kuryba_trumpai",
    Asmens_rysys_su_Vilniumi: "Asmens_rysys_su_Vilniumi",
    Apdovanojimai: "Apdovanojimai",
    Pagrindine_veikla: "Pagrindine_veikla",
    Palaidojimo_aprasymas: "Palaidojimo_aprasymas",
  },
  en: {
    Vardas_lietuviskai: "Vardas_pavarde_EN",
    Veikla_profesija: "Veikla_profesija_ANG",
    Veikla_kuryba_trumpai: "Veikla_kuryba_trumpai_ANG",
    Asmens_rysys_su_Vilniumi: "Asmens_rysys_su_Vilniumi_ANG",
    Apdovanojimai: "Apdovanojimai_ANG",
    Pagrindine_veikla: "Pagrindine_veikla_ANG",
    Palaidojimo_aprasymas: "Palaidojimo_aprasymas_ANG",
  },
  ru: {
    Vardas_lietuviskai: "Vardas_pavarde_RU",
    Veikla_profesija: "Veikla_profesija_RUS",
    Veikla_kuryba_trumpai: "Veikla_kuryba_trumpai_RUS",
    Asmens_rysys_su_Vilniumi: "Asmens_rysys_su_Vilniumi_RUS",
    Apdovanojimai: "Apdovanojimai_RUS",
    Pagrindine_veikla: "Pagrindine_veikla_RUS",
    Palaidojimo_aprasymas: "Palaidojimo_aprasymas_RUS",
  },
  pl: {
    Vardas_lietuviskai: "Vardas_pavarde_PL",
    Veikla_profesija: "Veikla_profesija_LEN",
    Veikla_kuryba_trumpai: "Veikla_kuryba_trumpai_LEN",
    Asmens_rysys_su_Vilniumi: "Asmens_rysys_su_Vilniumi_LEN",
    Apdovanojimai: "Apdovanojimai_LEN",
    Pagrindine_veikla: "Pagrindine_veikla_LEN",
    Palaidojimo_aprasymas: "Palaidojimo_aprasymas_LEN",
  },
};
